body {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow-x: hidden; */
}

code {
  font-family: 'Nunito Sans', sans-serif;
}

p{
  font-size: 14px;
}
link{
  text-decoration: none;
}