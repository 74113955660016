.catLink {
  text-decoration: none;
  font-size: 14px;
  color: grey;
  padding: 20px;
  border-radius: 5px;
}

.catLink:hover {
  color: white;
  background-color: #01a1de;

  border-radius: 5px;
  transition: background-color 0.8s, color 0.8s;
}
.catLink:hover img {
  filter: brightness(80000%);
}

.catBody {
  text-align: center;
  padding: 20px;
  border-radius: 10px;
}

.catBody:hover {
  color: white;
  background-color: #01a1de;

  border-radius: 5px;
}

.btn-hide {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: grey;
  margin-top: 10px;
}

.btn {
  color: grey !important;
}
