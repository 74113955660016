:root {
  --blue: #01a1de;
}

.detail_body {
  top: 22%;
  margin: auto;
  left: 0;
  right: 0;

  color: Black;

  width: 80%;
}

.pre-img-cont {
  width: auto;
  height: auto;
  align-items: center;
  text-align: center;
}

.detailcontainer {
  position: relative;
  width: 100%;
  height: 20rem;
}

.detailimage {
  display: block;
  width: 100%;
}

.overlaydetail {
  position: absolute;
  top: 0;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.7455357142857143) 39%,
    rgba(26, 25, 28, 0.44021358543417366) 100%
  );
  /* Black see-through */
  width: 100%;
  transition: 0.5s ease;
  padding: 60px 0;
  /* text-align: center; */
}

.modelreview {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  background-color: white;
  /* border: 2px solid #000; */
 
}

.overlaydetail {
  opacity: 1;
}

.pre-img {
  width: 100%;
}

.ratingtag {
  font-size: 0.8rem;
  font-weight: 600;
  color: #ffffff;
  background-color: #febe42;
  border-radius: 4px;
  padding: 0.3rem 0.6rem;
}

.map {
  height: 50vh;
}

.review {
  padding: 25px;
  max-height: 75ch;
  overflow-y: scroll;
}
.r_title {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 0 5px;
}
.r_name {
  font-size: 12px;
  margin: 5px 5px 10px 5px;
}
.btn_review {
  background-color: #01a1de !important;
}

/* .other {
  background-color: "#eee" !important;
} */
.other_section1 {
  max-height: 40ch;
  overflow-y: scroll;
}

.gheight {
  height: 30px !important;
}
.d-img {
  max-width: 30%;
  height: auto;
}
.d-img:hover {
  transform: scale(1.5);
  transition: transform 1s ease;
  cursor: pointer;
}
.r-img {
  max-width: 25%;
}
.r-img:hover {
  transform: scale(1.5);
  transition: transform 1s ease;
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .d-serach-Cont {
    padding: 0 20px;
  }
  .overlaydetail {
    padding: 100px 0;
    height: 30rem;
  }
  .d-img {
    max-width: 25%;
    height: auto;
  }
  .r-img {
    max-width: 25%;
    padding: 0 !important;
  }
  .overlaydetail {
    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.7455357142857143) 39%,
      rgba(26, 25, 28, 0.44021358543417366) 100%
    );
    height: 40rem;
  }

  .detail_body {
    position: absolute;
    top: 10%;
    margin: auto;
    /* margin-left:3rem ; */
    color: Black;

    width: 100%;
    text-align: center;
  }

  .modelreview {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    background-color: white;
    border: 2px solid #000;
  }

  .pre-img {
    width: 75%;
    height: 200px;
    margin-top: 0px;
  }

  .rat_cont {
    justify-content: center;
  }

  .pre-img-cont {
    text-align: center;
  }

  .title {
    text-align: center;
    margin-top: 10px;
  }

  .detail_gradient {
    height: 90vh;
  }
}
@media only screen and (max-width: 768px) {
  .d-img {
    max-width: 27%;
  }
}
