.blogstags {
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 5px 8px;
  font-size: 14px;
  height: fit-content;
}
.blogstitle {
  font-size: 24px;
  font-size: 400 !important;
}
.blogsdescp {
  font-size: 16px;
}

.b_card {
  cursor: pointer;
}
/* share button */
.wrapper .button {
  display: inline-block;
  height: 40px;
  width: 40px;
  float: left;
  margin: 0 5px 20px 0;
  overflow: hidden;
  background: #fff;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-out;
}
.wrapper .button:hover {
  width: 150px;
}
.wrapper .button .icon {
  display: inline-block;
  height: 50px;
  width: 38px;
  text-align: center;
  border-radius: 50px;
  box-sizing: border-box;
  line-height: 42px;
  transition: all 0.3s ease-out;
}

.wrapper .button .icon i {
  font-size: 25px;
  line-height: 60px;
  transition: all 0.3s ease-out;
}
.wrapper .button:hover .icon i {
  color: #fff;
}
.wrapper .button span {
  font-size: 20px;
  font-weight: 500;
  line-height: 60px;
  margin-left: 10px;
  transition: all 0.3s ease-out;
}
.wrapper .button:nth-child(1) span {
  color: #4267b2;
}
.iconbtn {
  display: inline-block;
  height: 50px;
  width: 50px;
  text-align: center;
  border-radius: 50px;
  box-sizing: border-box;
  line-height: 60px;
  transition: all 0.3s ease-out;
}
.blogs_date {
  background-color: #eee;
  border-radius: 4px;
  font-size: 14px;
  padding: 1px 5px;
  height: fit-content;
}
.blogs_title {
  font-size: 20px;
  margin: 0;
  font-weight: 600;
  margin-bottom: 5px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.blogs_descp {
  font-size: 14px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.blog_cattag {
  position: absolute;
  bottom: 10px;
  left: 30px;
  background: white;
  border-radius: 8px;
  padding: 5px 20px;
  color: black;
  text-align: center;
}
.ql-editor {
  min-height: 20rem !important;
  overflow-y: scroll !important;
}
.blog_modal {
  height: 600px !important;
  overflow-y: scroll !important;
}
.blog-content img {
  max-width: 100%;
}
.blog-rr-title {
  font-weight: 600;
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 50px;
}
.blogs-rr-date {
  background-color: #f0f0f0;
  font-size: 14px;
  width: fit-content;
  padding: 2px 8px;
  border-radius: 4px;
  color: #8e8c96;
}
.blog-rr-cont {
  position: relative;
  background-position: 1000px 1000px;
  background-repeat: no-repeat;
  background-size: 100% auto;
  /* height:auto; */
  padding: 10px;
  border-radius: 8px;
}
.blog-rr-cont:hover {
  background-size: 100% auto;
  background-position: center center;
  cursor: pointer;
  color: white;
  opacity: 2;
}
.blog-rr-cont1 {
  padding: 20px 40px;
  /* opacity: 0.5; */
  z-index: 1 !important;
}

.blog-layer {
  display: none;
  background-color: rgba(102, 99, 98, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.blog-rr-cont:hover .blog-rr-title {
  z-index: 1 !important;
  color: #fff !important;
}
.blog-main-cont {
  padding: 4rem 0 8rem 0 !important;
}
.ql-size-large {
  font-size: 16px;
}
.ql-size-normal {
  font-size: 14px;
}
.ql-size-normal {
  font-size: 12px;
}
.ql-size-huge {
  font-size: 20px;
}
@media only screen and (max-width: 600px) {
  .blog-main-cont {
    padding: 4rem 1rem 8rem 1rem !important;
  }
}
