:root {
  --blue: #01a1de;
}

.homebg {
  background-image: url("https://lp-cms-production.imgix.net/2019-06/9483508eeee2b78a7356a15ed9c337a1-bengaluru-bangalore.jpg");

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* width: 100%; */
  height: 80vh;
}

.home_gradient {
  /* width: 100%; */
  z-index: 1;
  height: 80vh;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    to right,
    rgba(250, 250, 250, 1) 20%,
    rgba(250, 250, 250, 0.95) 50%,
    rgba(250, 250, 250, 0.1) 80%,
    rgba(250, 250, 250, 0) 100%
  );
}

.home_body {
  margin-right: auto;
  /* margin-left: 8em !important; */
  transform: translate(6rem, 4rem);
  /* z-index: 1; */

  color: Black;
  padding: 20px;
  width: 90%;
}

.banner_headline {
  max-width: 50%;
}

.banner_p {
  background-color: var(--blue);
  color: white;
  width: auto;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  text-align: center;
  margin-left: 5px;
  font-size: 14px;
}

.home_button {
  padding: 16px !important;
  margin-top: 3rem !important;
  color: white !important;
  background-color: #01a1de !important;
}
.search_button {
  padding: 16px !important;
  width: 100%;

  color: white !important;
  background-color: #01a1de !important;
}
.banner_pp {
  width: fit-content !important;
}

.cityButton_cont {
  text-align: end;
  padding: 0;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 0.5rem;
  padding: 1rem;
}

.cityButton {
  color: black !important;
  min-width: 1 !important;
  padding: 0 !important;
  min-width: auto !important;
}

.citylist_cont {
  display: flex;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
}

.cityList_item {
  /* padding: 1rem; */
  padding: 0.5rem;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  text-transform: capitalize;
  border: 1px solid #fff;
}

.cityList_item:hover {
  border: 1px solid #01a1de;
  border-radius: 4px;
}
.search_body {
  margin: auto;
  margin: 2rem auto;
  position: sticky;
}

.citymodal {
  background-color: #fff;
  /* width: 35%; */
  margin: auto;
  margin-top: 5rem;
  border: none;
  outline: none;
  border-radius: 4px;
  box-shadow: -4px -1px 60px -12px rgba(0, 0, 0, 0.31);
}
.counter {
  font-size: 24px;
  font-weight: 800;
}
/* .single_member_counter {
  border-right: 1px solid red;
  text-align: center;
} */
.single_member_counter h5 {
  color: #8f918f;
  font-weight: 500;
  font-size: 20px;
}
.rr_date {
  width: fit-content;
  padding: 4px;
  border-radius: 4px;
  background: rgb(224, 219, 219);
  margin: 0px 0px 10px 5px;
}
/* slick-button*/
.slick-prev:before,
.slick-next:before {
  color: black !important;
}
.rr_card {
  border: 2px solid transparent;
  /* transition: all ease 0.3s; */
}
.rr_card:hover {
  cursor: pointer;
  border: 2px solid var(--blue);
  transition: all ease 0.4s;
}
.rr_title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.rr_descp {
  display: block;
  display: -webkit-box;
  height: 40px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.single_member_counter:not(:last-child) {
  border-right: 1px solid #e0e0e0;
}

@media only screen and (max-width: 600px) {
  .search_button {
    width: fit-content;
  }
  .mb-10-serach {
    margin-bottom: 10px;
  }
  .serach-txt-center {
    text-align: center !important;
  }
  .homebg {
    height: 60vh !important;
  }
  .rr-p {
    padding: 35px;
  }

  .home_gradient {
    height: 60vh;
  }

  .home_body {
    transform: translate(1rem, 1rem) !important;
  }

  .banner_pp {
    margin-top: 10px;
    width: fit-content !important;
  }

  .banner_title {
    width: 85% !important;
  }

  .banner_headline {
    max-width: 80%;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }

  .banner_p {
    background-color: var(--blue);
    color: white;
    width: auto;
    padding: 5px;
    border-radius: 5px;
    text-align: center;
    margin-left: 5px;
    margin-top: 10px;
  }

  .banner_pp {
    margin-top: 10px;
    width: fit-content !important;
  }

  .home_button {
    text-align: center;
    padding: 8px 40px 8px 40px !important;
    margin-top: 1rem !important;
    color: white !important;
    background-color: #01a1de !important;
  }

  .btn-col {
    text-align: center;
  }
  .counter {
    display: none;
  }
}
@media only screen and (max-width: 820px) {
  .homebg {
    height: 40vh;
  }
  .counter {
    display: none;
  }
  .home_body {
    transform: translate(3rem, 4rem);
  }
}
@media only screen and (max-width: 375px) {
  .homebg {
    height: 80vh !important;
  }
  .counter {
    display: none;
  }
  .home_body {
    transform: translate(3rem, 4rem);
  }
  .home_gradient {
    height: 80vh !important;
  }
}
