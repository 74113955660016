.fotBody {
  background: #303030;
  min-height: 48vh;
  color: #ffffff;
  overflow-x: hidden;
}
.terms_f1 {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
}

.terms_f2 {
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
}
.terms_main {
  padding: 10px 40px;
}
.terms_main h2 {
  font-size: 24px;
  font-weight: 600;
}
.terms_main p {
  font-size: 16px;
  font-weight: 400;
}
.footer_link {
  text-decoration: none;
  color: #c0b7a4;
  cursor: pointer;
}
.footer_link:hover {
  color: #c0b7a4;
  cursor: pointer;
}

/* Contact us */
.cu_cont1 {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}
.cu_divider {
  width: 10%;
  height: 5px !important;
  margin-bottom: 10px !important;
  color: var(--blue);
  opacity: 1;
}
.cu_f1 {
  font-size: 16px;
  font-weight: 500;
  color: #808080;
}
.cu_link {
  color: var(--blue);
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
}
.cu_main {
  padding: 6rem 4rem;
}
.cu_h1 {
  font-size: 30px;
  font-weight: 600;
  margin: 10px 0 40px 0;
}
.cu_h3 {
  font-size: 20px;
  font-weight: 600;
}
.cu_f3 {
  font-size: 20px;
  font-weight: 400;
  margin: 40px 0 20px 0;
}
.cu_pd {
  padding: 0 100px;
}
.rb_tag {
  background-color: #01a1de;
  color: #fff;
  width: fit-content;
  padding: 2px 8px;

  border-radius: 4px;
  margin: 5px auto;
}
.rb-arrowiimg {
  width: 40%;
  margin-top: 100px;
  display: block;
}
.rb_arrowDown {
  display: none;
}
.rb-ab-p {
  font-size: 16px;
}
.rb-ab-img {
  border: 1px solid black;
  border-radius: 50px;
  width: fit-content;
  padding: 10px;
  margin: 10px auto;
}
.rb-ab-p {
  font-size: 16px;
}
@media only screen and (max-width: 770px) {
  .rb-arrowiimg {
    width: 40%;
    margin-top: 100px;
    display: none;
  }
  .rb_arrowDown {
    width: 20%;
    /* margin-top: 100px; */
    display: block;
    margin: auto;
  }
}
