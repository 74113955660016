.location {
  max-height: 109vh;
  width: 100%;
  z-index: auto;
}

.serviceimg {
  width: 100%;
  height: auto;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.card-body:hover {
  border-left: 5px solid #01a1de;
  margin: 5px;
  color: #01a1de;
}
.Link {
  text-decoration: none;
  color: grey;
}

.custom_check {
  color: #666;
  display: inline-block;
  position: relative;
  font-size: 14px;
  font-size: 0.9375rem;
  padding-left: 30px;
  margin-bottom: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.custom_check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.custom_check input:checked ~ .checkmark {
  background-color: #fff;
}
.custom_check .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #dcdcdc;
  background-color: #fff;
  border-radius: 3px;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.custom_check .checkmark::after {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  display: none;
  left: 4px;
  top: 0;
  color: #40d2a1;
  font-size: 11px;
}
.sl-decp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}
.custom_check input:checked ~ .checkmark:after {
  display: block;
}
.p-search {
  padding: 0 10px 0px 10px !important;
  margin-bottom: 30px;
  margin-top: 30px;
}
.service_title {
  font-weight: 600;
  font-size: 18px;
}
.service-rating {
  background: #febe42;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
  margin-bottom: 5px;
}
.service-review {
  font-weight: 600;
}
.pd-9 {
  padding-left: 50px;
}
@media only screen and (max-width: 600px) {
  .st-center {
    text-align: center !important;
  }
  .serviceimg {
    width: 30%;
    height: auto;
  }
  .sd-media {
    align-items: center !important;
    justify-content: center !important;
  }
  .pd-9 {
    padding-left: 0;
    padding: 0 40px;
  }
}
