link {
    text-decoration: none;
    color: black
}

;

.buttonclose {
    color: black !important;
    margin-left: 20rem;
}

.modal_back {
    background-color: #fff;
    width: 40%;
    transform: translate(70%, 10%);
}