.discuss_tags {
  background-color: #f4f4f4;
  font-size: 14px;
  padding: 5px 10px;
  width: fit-content;
  border-radius: 5px;
}
.discuss_font {
  font-size: 16px;
}
.discuss_title {
  font-size: 24px;
}
.discuss-tre-title {
  font-size: 16px;
  font-weight: 600;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
}
.dicuss-card:hover {
  cursor: pointer;
}

.discuss_sidetags {
  background-color: #f4f4f4;
  padding: 5px 10px;
  text-align: center;
}
.boder {
  border-right: 1px solid #d0d0d0;
  width: fit-content;
}
.discuss_font2 {
  font-size: 20px;
  font-weight: 700;
}
.dicuss_fontColor {
  color: #8f8a99;
  font-size: 16px;
  font-weight: 500;
}
.discuss_bb {
  border-bottom: 1px solid #d0d0d0;
  cursor: pointer;
}
.discuss_bb:last-child {
  border-bottom: none;
}

.category_border {
  font-size: 16px;
  font-weight: 500;
  padding: 10px 10px;
  border-bottom: 1px solid #d0d0d0;
  cursor: pointer;
  margin-bottom: 0;
}
.category_border:last-child {
  border-bottom: none;
}
.categoryActive {
  border-right: 5px solid #01a1de;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 5px;
  border-bottom: 1px solid #d0d0d0;
  cursor: pointer;
  margin-bottom: 0;
}
.d_date {
  background-color: #e2dfdff8;
  width: fit-content;
  padding: 2px 8px;
  border-radius: 4px;
  color: grey;
  font-size: 14px;
}
.d_content {
  font-size: 14px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
}
.d-cm-section {
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 20px;
}
